import React from 'react'; 
import './Footer.scss';
import FooterpictureHouse from './eon-house.png'
import { useTranslation } from 'react-i18next';

function Footer  ({versionNumber}: any) {

	const {t} = useTranslation();

		return (
			<div className='container-fluid'>
				<div className='row eon-footer'>
				<div className='col-12 footer-pic '>
					<img className="float-right" src={FooterpictureHouse} alt="footerpicture" />
				</div>
				<div className='col-12'>
					<div className='row secound-row p-4'>
						<div className='col-md-4 p-2'>
							<div className='ml-4 contact-text'>
								<p>
									<b>
										Solar Supply Sweden AB
									</b>
								</p>
								<br />
								<p>Carlsgatan 22</p>
								<p>211 20 Malmö</p>
								<p>E-post: <a className="footer-info__email" href="mailto:info@solarsupply.se">info@solarsupply.se</a></p>
								<p>Org nr: 556872-7464</p>
							</div>

						</div>
						<div className='col-md-4'>
							<p className='center'>
								Prenumerera på vårt nyhetsbrev!
							</p>
							<div className='d-flex justify-content-center'>
								<button className="btn btn-primary mr-2 align-center"> 
									<a className="link" href="https://gansub.com/s/l/wH9AKRT6/4692990075536/" target="_blank" rel="noopener noreferrer">Prenumerera</a>
								</button>
							</div>
						</div>
						<div className='col-md-4 copyright-column'>
								<p className='copyright'>
									<b>Solar Supply Sweden AB © Copyright 2020</b>
								</p>
								<p className='version'>
									{t('common.Version')}: {versionNumber}
								</p>
						</div>
					</div>
				</div>
				</div>
			</div>
		)
}

export default Footer;