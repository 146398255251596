import {useEffect, useState} from 'react'
import { FormContextValues } from 'react-hook-form';
import DatePicker from '../../components/Form/DatePicker';
import { InlineCheckbox } from '../../components/Form/InlineCheckInput';
import InputField from '../../components/Form/InputField';
import { SerialNumbersAdd } from '../../components/Order/SerialNumbersAdd';
import priceFormatter from '../../lib/priceFormatter';
import { formatTimeFromDate } from '../../lib/timeHelper';
import { cartItem } from '../../store/orders/types';

type EditOrderArticleRowProperties = {
	handleInput: FormContextValues<any>;
	cartItem: cartItem,
	currency: string;
	index: number;
	edit?: boolean
	disablePartialDelivery?: boolean
	userAdminType: boolean
	userSuperAdminType: boolean
}

export function EditOrderArticleRow({
	handleInput,
	cartItem,
	currency,
	index,
	edit,
	disablePartialDelivery,
	userAdminType,
	userSuperAdminType
}: EditOrderArticleRowProperties) {

	const [totalPrice, setTotalPrice] = useState(Number)
	const [discountCost, setDiscountCost] = useState<number>(0);
	const name = `shopOrderCartItems[${index}]`
	var watcher = handleInput.watch(name+'.quantity')
	useEffect(() => {
		if(watcher == undefined) return
		setTotalPrice(cartItem.shopOrderPrice.priceWithPriceListAndPriceGroup*watcher) 
	},[watcher,totalPrice])

	useEffect(() => {
		if(totalPrice === undefined) return;
		setDiscountCost((totalPrice / 100) * cartItem.articleDiscount);
	}, [cartItem.articleDiscount, totalPrice])

	return (
		<>
			<td className='text-center' style={{ width: 100}}>{cartItem.fortnoxProductId}</td>
			
			<td className='text-truncate'>
					{cartItem.articleName}
					<SerialNumbersAdd serialNumbers={cartItem.productInstances.map(x => x.serialNumber)} />
			</td>
			<td style={{ width: 100}}>
				{ edit ?
					<>
						<InputField
							name={name+'.articleId'}
							handler={handleInput}
							label=""
							className="d-none min-height-fix"
						/>
						<InputField
								name={name+'.fortnoxProductId'}
								handler={handleInput}
								label=""
								className="d-none min-height-fix"
						/>
						<InputField
							name={name+'.quantity'}
							handler={handleInput}
							disabled={!edit}
							placeholder="Antal"
						/>
					</>
					: cartItem.quantity+'st'
				}
			</td>
			{ userSuperAdminType &&
				<td className='pl-2' style={{ width: 80}}>
					{cartItem.fnDisposableQuantity}
				</td>
			}
			{ userAdminType &&
				<td className='pl-2' style={{ width: 80}}>
					{cartItem.quantityInStock}
				</td>
			}
			<td className='pl-2 pr-2 text-truncate'> 
				{priceFormatter(currency || 'SEK')(cartItem.shopOrderPrice.priceWithPriceListAndPriceGroup)}
			</td>
			<td className='pl-2 pr-2 text-truncate'>
				{cartItem.articleDiscount > 0 ? cartItem.articleDiscount : 0}%
			</td>
			<td className='pl-2 pr-2 text-truncate'>
				{edit ? 
					priceFormatter(currency || 'SEK')(totalPrice - discountCost)
				:
					priceFormatter(currency || 'SEK')((cartItem.shopOrderPrice.priceWithPriceListAndPriceGroup * cartItem.quantity) - discountCost)
				}
			</td>
			<td className='p-0' style={{ width: 80}}>
				<InlineCheckbox
					name={name+'.partlyDeliveryArticle'}
					options={[{	value: '1', label: '' }]}
					handler={handleInput}
					disabled={!edit || disablePartialDelivery}
					className="p-0"
				/>
			</td>
			<td style={{ width: 100}}>
				{ edit ?
					<DatePicker
						name={name+'.dateThatThisProductWillComeToStock'}
						handler={handleInput}
						defaultValue={cartItem.dateThatThisProductWillComeToStock ? new Date(cartItem.dateThatThisProductWillComeToStock as any) : undefined}
						placeholder="Datum"
					/>
					: cartItem.dateThatThisProductWillComeToStock && formatTimeFromDate(cartItem.dateThatThisProductWillComeToStock)
				}
			</td>
			
		</>
	)
}

export default EditOrderArticleRow;