import React from 'react'

const DeliveryTerms = () =>
    <>
		<h4 className="mb-4 mt-4"><b>Allmänna leveransvillkor</b></h4>
		<p>Leveranser sker till de leveranstider och på de leveransvillkor som framgår av ordererkännande eller annars i Avtalet. Om annat inte anges gäller Allmänna Leveransbestämmelser NL09 (NL 17) för de materialleveranser som Solar Supply utför.</p>
		<p>För sådana leveranser gäller leveransvillkor Ex Works och med skälig leveranstid, med beaktande av tillverkares leveranstider (inkluderat eventuella förseningar av desamma) och de andra förseningar eller hinder som kan uppstå. Solar Supply äger alltid rätt att ta ut fraktkostnader för leveranser som Solar Supply ombesörjt.</p>

		<p>Fraktkostnader för din beställning genom kundportalen syns först på din faktura när ordern är packad och skickad från lagret.</p>
		<p>Obs. Vid ändring av lagd och bekräftad order, tillkommer en administrationsavgift på 100 kr.</p>

		<h4 className="mb-4"><b>Returvillkor</b></h4>
		<p>Det åligger beställande återförsäljare/kund att kontrollera godset i sin helhet vid leveransmottagning och rapportera synliga skador på transportörens fraktsedel vid kvittens. Dolda skador/saknat material ska rapporteras inom 7 dagar från mottagen leverans till Solar Supply.</p>

		<h4 className="mb-4"><b>Vad får returneras?</b></h4>
		<p>Endast produkter som är köpta hos Solar Supply mottages i retur</p>
		<p>Kit-artikel skall returneras komplett med samtliga underartiklar</p>
		<p>Produkten skall vara oanvänd, oskadad, ren och i leverantörens förpackning (i förekommande fall)</p>
		<p>Produkter som är specialbeställda och som normalt ej lagerförs tas ej i retur</p>
		<br />

		<h4 className="mb-4"><b>Hur går jag till väga?</b></h4>
		<p>Kontakta alltid Solar Supply på info@solarsupply.se, innan gods returneras för att erhålla returärendenummer</p>
		<p>Returblankett med ordernummer och ärendenummer ska bifogas returgodset</p>
		<p>Produkter skall emballeras för att klara returtransport</p>
		<p>Returfrakt bokas av Solar Supply</p>
		<p>Godkänd retur ersätts i form av kreditnota (se returavgifter)</p>

		<h4 className="mb-4"><b>Ersättningsvillkor och avgifter</b></h4>
		<p>Produkter som returneras inom två (2) veckor från mottagen leverans, krediteras till 100%</p>
		<p>Produkter som returneras inom två (2) månader från mottagen leverans, krediteras till 70%</p>
		<p>Produkter som returneras inom tre (3) månader från mottagen leverans, krediteras till 60%</p>
		<p>Projektbeställningar från Renusol som returneras inom tre (3) månader från mottagen leverans, krediteras till 70%</p>
		<p>Skadade produkter och/eller produkter som inte uppfyller returvillkoren ovan, returneras på mottagarens bekostnad</p>
		<p>Produkter som ankommer skadade till Solar Supply ersätts ej</p>
		<p className="mb-4">Avdrag på minst 3000 SEK görs för skadat emballage, eventuell ompackning och för täckning av omkostnader i förekommande fall där ovanstående returvillkor inte uppfyllts</p>
		<p></p>
		<p className="mt-2"><b>* Returfrakt betalas av avsändaren.</b></p>
		<p className="mt-2"><b>* Om returbehov uppkommit till följd av felplock av Solar Supply debiteras ingen returfraktkostnad.</b></p>
	</>

export default DeliveryTerms