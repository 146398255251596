import React, {useState, useCallback, useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import {PATH_AUTH_LOGIN} from '../../router/paths';

import auth from '../../services/auth';

import {AppState} from '../../store';
import {setShowLoader} from '../../store/system';

import {InlineCheckbox} from '../../components/Form/InlineCheckInput';
import NewPasswordForm, {
  NewPasswordFormInput,
} from '../../components/Auth/NewPasswordForm';

import {ReactComponent as Logo} from './svgs/Solar_Supply-logo_-_EON.svg';

const connector = connect(
  (state: AppState) => ({
    system: state.system,
  }),
  {setShowLoader}
);

type HomeProps = ConnectedProps<typeof connector> & {};

const textContainer: React.CSSProperties = {
  maxHeight: '300px',
  overflow: 'auto scroll',
  marginBottom: '1.25rem',
};

export function Home({setShowLoader}: HomeProps) {
  const {t} = useTranslation();
  const history = useHistory();
  const {token} = useParams<{token:string}>();

  const [gdprAccepted, setGdprAccepted] = useState(false);

  const handleInput = useForm<NewPasswordFormInput>({
    validateCriteriaMode: 'all',
    mode: 'onChange',
  });

  const {
    formState: {isSubmitting},
  } = handleInput;

  const onAccept = useCallback(() => {
    setGdprAccepted(true);
  }, []);

  const onSubmit = async (inputs: NewPasswordFormInput) => {
    try {
      await auth.confirmUser({token, password: inputs.password});

      history.push(PATH_AUTH_LOGIN);
    } catch (e) {
      /* istanbul ignore else */
      if (e.isAxiosError) {
        handleInput.setError(
          'passwordAgain',
          `${t('validation.Invalid token')}: ${token}`
        );
      } else {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    setShowLoader(isSubmitting);
  }, [isSubmitting, setShowLoader]);

  if (!gdprAccepted) {
    return <Gdpr onAccept={onAccept} />;
  }

  return <NewPasswordForm handleInput={handleInput} onSubmit={onSubmit} />;
}

function Gdpr({onAccept}: {onAccept: () => void}) {
  const {t} = useTranslation();
  const {replace} = useHistory();

  const handler = useForm();

  const submit = () => {
    onAccept();
  };

  const onReject = () => replace(PATH_AUTH_LOGIN)

  return (
    <form onSubmit={handler.handleSubmit(submit)} className="position-relative">
      <legend className="primary">{t('navigation.Gdpr')}</legend>
      <Logo className="logo" />

      <div style={textContainer}>
        <GDPRText />
      </div>
      <hr className="form-delimiter" />

      <InlineCheckbox
        name="gdpr"
        handler={handler}
        options={[
          {value: 'accept', label: 'Jag har läst och förstått hur Solar Supply hanterar mina personuppgifter'},
        ]}
        rules={{required: true}}
      />

      <hr className="form-delimiter" />

      <button type="button" className="btn btn-info mr-2" onClick={onReject}>
        {t('common.Reject')}
      </button>
      <button
        type="submit"
        className="btn btn-primary "
        data-testid="submit__company_form"
      >
        {t('common.Accept')}
      </button>
    </form>
  );
}

export default connector(Home);

export const GDPRText = () => (
    <>
        <p><strong>Information om personuppgiftshantering</strong></p>
        <p>Vi är måna om att dina personuppgifter behandlas på ett riktigt och korrekt sätt! Läs mer om vår personuppgiftshantering nedan.</p>

        <p><strong>Personuppgiftsansvarig</strong></p>
        <p>Solar Supply Sweden AB, org.nr 556872-7464, (nedan ”Solar Supply”) är personuppgiftsansvariga för de uppgifter som du lämnar till oss via vår kundportal. </p>

        <p><strong>Ändamål</strong></p>
        <p>Vi behandlar dina personuppgifter för att du som återförsäljare på ett säkert och tryggt sätt ska kunna nyttja kundportalen och genom den få en offert eller kunna ingå ett avtal om köp och leverans av solceller med oss. Vi behandla också dina uppgifter för att kunna svara på frågor rörande produkten, leverera och fakturera för tjänsten/produkten samt för att hantera reklamationer och garantier.</p>
        <p>Om du inte tillhandahåller oss dessa uppgifter så kan du inte använda portalen för att får offerter eller köp och leverans av solceller.</p>
        
		<p><strong>Rättslig grund för vår behandling av personuppgifter</strong></p>
        <p>Den rättsliga grunden för vår behandling rörande de uppgifter som du lämnar till oss i Kundportalen är avtal och legal skyldighet.</p>
        <p>Den legala grunden för vår behandling av endpoint-anropen är Intresseavvägning. Vårt berättigade intresse av att spara dessa uppgifter är enbart av säkerhetsprincip för att kunna spåra ifall något fel uppträder i systemet och väger tyngre än intrånget i den personliga integriteten.</p>
        
		<p><strong>Kategorier av personuppgifter som behandlas</strong></p>
        <p>De personuppgifter som vi behandlar om dig vid offertförfrågningar, avtalsteckning, frågor, reklamationer m.m. är kontaktuppgifter så som tex. Namn, efternamn, adress, email adress, telefonnummer eller andra liknande uppgifter som du har lämnat direkt till oss för att vi ska kunna hantera din förfrågan eller ditt köp och leverans av solceller.</p>
        <p>När du använder portalen så loggar vi också endpoint-anropen som du som kund anropar (API in till kundportalen från GUI), dvs de händelser som du som kund gör i systemet.</p>
        
		<p><strong>Mottagare eller kategorier av mottagare</strong></p>
		<p>Personuppgifterna som du har lämnat till oss kan, förutom av oss, komma att behandlas av:</p>
        <ol>
            <li>Våra samarbetspartners så som tex men inte uteslutande, lagerhållare och transportörer. Det kan vara för att vi har ett samarbete som kräver att vi delger vissa uppgifter till våra samarbetspartner och det kan vara för att utföra de uppgifter som berörd person har begärt.</li>
            <li>våra personuppgiftsbiträden och deras underbiträden. Personuppgifterna kan komma att lämnas ut till våra personuppgiftsbiträden och deras ev underbiträden så som till exempel men inte uteslutande systemleverantörer och andra IT leverantörer. Om så är fallet, har vi säkerställt att överföringen är säker och att den uppfyller alla legala krav.</li>
        </ol>
		
        <p><strong>Behandling av personuppgifter i tredje land</strong></p>
        <p>Personuppgifter lämnas normalt inte till bolag i länder utanför EU/EES området. </p>
        <p>Om personuppgifter behandlas i ett land utanför EU/EES området så har vi, för att säkerställa en säker hantering, vidtagit lämpliga skyddsåtgärder. I de flesta fall är detta i form av ett godkänt standardavtal, se <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en</a>.</p>
        
        <p><strong>Säkerhet</strong></p>
        <p>Vi vidtar alltid de tekniska och organisatoriska åtgärder som är möjliga för att skydda de personuppgifter som behandlas. Vårt syfte med detta är att se till att uppgifterna inte förloras, förstörs, manipuleras eller blir tillgängliga för obehöriga. Vi strävar mot att åstadkomma en säkerhetsnivå som är tillräckligt hög med hänsyn till de tekniska möjligheter som finns.</p>
        
        <p><strong>Gallring</strong></p>
        <p>Personuppgifter som du lämnar till oss gallras succesivt på följande sätt:</p>
        <ul>
            <li>Personuppgifter i offerter som inte leder till någon order gallras 5 dagar efter utgångsdatum har passerats.  Offerter som leder till order gallras efter 7 + innevarande år enligt bokföringslagen.</li>
            <li>Personuppgifter i order och kundfakturor sparas 7 + innevarande år enligt bokföringslagen.</li>
            <li>Personuppgifter i reklamationer/garantiärenden – gallras succesivt men sparas som längst till garantitiden har gått ut. För att kunna leva upp till produktgarantin på upp till tolv (12) år och effektgarantin på tjugofem (25) år kommer vi att spara produkternas tillverkarnamn, produktnamn, serienummer, organisationsnummer samt adressen där anläggningen är installerad (25) år efter det att installationen av solcellsanläggningen har utförts.</li>
            <li>Endpoint anropen, dvs de händelser som du som kund gör i systemet, sparas på kundkortet och gallras inom 14 dagar från det att du gjorde din senaste inloggning. Detta är enbart i säkerhetsprincip för att kunna spåra ifall något fel uppträder. Loggningen används ej av något automatiskt analysverktyg och kommer inte delas med någon part.</li>
            <li>Om du som användare inte varit aktiv under 4 månaders tid, så raderas ditt användarkonto i kundportalen.</li>
        </ul>
		<p>Övriga eventuella personuppgifter som du lämnar till oss gallras löpande när det inte längre finns något syfte och ändamål med behandlingen.</p>
		
        <p><strong>Samtycke</strong></p>
        <p>Om ni tidigare har lämnat ett samtycke kan ni på ett enkelt sätt återkalla detta. Vi kommer då inte att behandla ytterligare personuppgifter eller att uppdatera tidigare lämnade uppgifter om personen om samtycke är en förutsättning för behandling av personuppgifterna.</p>
       
	    <p><strong>Rätt att få information (Registerutdrag) m.m.</strong></p>
        <p>Du har rätt att få information om hur dina personuppgifter hanteras (registerutdrag).</p>
        <p>Du har också rätt att begära rättelse eller radering av förekommande personuppgifter. Likaså kan du begära begränsning av vår behandling av dina personuppgifter. Du har även rätt att invända mot vår behandling av förekommande personuppgifter. Om du själv har levererat vissa personuppgifter såsom att du exempelvis har producerat eller förbrukat en viss mängd energi och på så sätt givit upphov till någon form av förbrukningsmönster eller liknande har du rätt till dataportabilitet.</p>
        <p>Du kan utöva rättigheterna ovan genom att kontakta oss på <a href="mailto:info@solarsupply.se">info@solarsupply.se</a> . Du behöver också besöka vårt kontor på Carlsgatan 22 i Malmö och identifiera dig med hjälp av ID, pass eller likande. </p>
        
		<p><strong>Klagomål till Integritetsskyddsmyndigheten</strong></p>
        <p>Om du är missnöjd med vår behandling av förekommande personuppgifter har du rätt att lämna ett klagomål till Integritetsskyddsmyndigheten (IMY), det gör du på e-post: <a href="mailto:imy@imy.se">imy@imy.se</a> </p>
        
		<p><strong>Kontakt</strong></p>
        <p>Om du har frågor om vår hantering av förekommande personuppgifter kan du komma i kontakt med oss på info@solarsupply.se</p>
        <p>Du kan även kontakta vårt dataskyddsombud på <a href="mailto:dataskyddsombud@eon.se">dataskyddsombud@eon.se</a> eller 040 – 25 50 00. </p>
    </>
);
